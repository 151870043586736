import React, { Component } from 'react';
import "./hiddenEmail.css"
import emailIcon from "../../images/gmail.svg"
class HiddenEmail extends Component {
      // constructor to set state and bind "this"
      constructor(props) {
        super(props);
        this.state = {showEmail: false};
        // this.handleClick = this.handleClick.bind(this);
      }

     clickEmailHandler= () => {
        this.setState({
            showEmail: true
        })
    }
    render() {
    let emailDiv = null;
    if (this.state.showEmail){
        emailDiv = (
            <div className="email "><img src={emailIcon}></img><a  href={`mailto:`+this.props.email}>{this.props.email}</a></div>
        )
    }else{
        emailDiv = (
            <div className="email hidden">Click Here to show my email</div>
    )
    }

    return(
        <div onClick={this.clickEmailHandler}>
            {emailDiv}
        </div>
    )
    }
}

export default HiddenEmail;