import React,{useState} from "react"
import Layout from "../components/layout"
import HiddenEmail from "../components/HiddenEmail/hiddenEmail"
// TODO: make this a component 
export default () => (


  <Layout>
    <h1>Contact Me</h1>
    <p>The best way to contact me would be through my email</p>
      <HiddenEmail email="georgenancejr@gmail.com"></HiddenEmail>
    
  </Layout>
)